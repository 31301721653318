<template>
    <header>
		<div class="preloader" v-if="loading">
			<div class="circular-spinner">
				<img src="../assets/images/shiba-loading.png">
			</div>
		</div>
		<nav class="x-header js-header-selector navbar navbar-expand-lg -anon -sticky">
		<div class="container-fluid -inner-container">
			<div class="">
				<button type="button" class="btn bg-transparent p-0 x-hamburger" data-toggle="modal" data-target="#themeSwitcherModal">
						<span></span>
						<span></span>
						<span></span>
				</button>
			</div>
			<div id="headerBrand">
				<router-link to="/" class="navbar-brand">
					<img class="-logo -default" src="../assets/images/logo.png" alt="Winner88">
					<img class="-logo -invert"
						src="../assets/images/logo.png"
						alt="Winner88">
				</router-link>
			</div>
			<div class="x-menu">
				<div class="-menu-container">
					<router-link to="/provider" :class="{'-menu-link':true,'active':(mainMenu === 'provider')?true:false}"><span>Slot</span></router-link>
					<router-link to="/casino" :class="{'-menu-link':true,'active':(mainMenu === 'casino')?true:false}"><span>คาสิโน</span></router-link>
          <a href="/playsport" target="_blank" class="-menu-link"><span>กีฬา</span></a>
					<router-link to="/event" :class="{'-menu-link':true,'active':(mainMenu === 'event')?true:false}"><span>สิทธิพิเศษ</span></router-link>
					<router-link to="/promotion" :class="{'-menu-link':true,'active':(mainMenu === 'promotion')?true:false}"><span>โปรโมชั่น</span></router-link>
          <router-link to="/cashback" :class="{'-menu-link':true,'active':(mainMenu === 'cashback')?true:false}"><span>คืนยอดเล่น</span></router-link>
          <router-link to="/continue" :class="{'-menu-link':true,'active':(mainMenu === 'continue')?true:false}"><span>ฝากต่อเนื่อง</span></router-link>
          <router-link to="/partner" :class="{'-menu-link':true,'active':(mainMenu === 'partner')?true:false}"><span>พันธมิตร</span></router-link>
          <!-- <router-link to="/rewards" :class="{'-menu-link':true,'active':(mainMenu === 'rewards')?true:false}"><span>แลกของรางวัล</span></router-link> -->
				</div>
			</div>

			<div id="headerContent">
				<div class="d-flex" v-if="!user.key">
					<a href="#loginModal" class="-btn-header-register btn f-sm-6 f-7 mr-1 mr-sm-3" data-toggle="modal" data-target="#registerModal">
						สมัครสมาชิก
					</a>
					<a href="#loginModal" class="-btn-header-login btn f-sm-6 f-7" data-toggle="modal" data-target="#loginModal">
						เข้าสู่ระบบ
					</a>
				</div>
				<div class="x-logged" v-else>
          <div class="x-ranking-entrance">
            <router-link to="/rewards" class="-link-wrapper">
                <div class="-image-wrapper">
                    <img class="img-fluid -ic-right-star-rank" src="../assets/images/coins.png" alt="Winner88 แลกของรางวัล">
                </div>
                <div class="-text-wrapper">{{numberWithCommas2(user.coin)}}</div>
            </router-link>
        </div>
				<div class="-balance-container">
					<div class="-text-username">
						{{user.mobile}}
					</div>

												
					<div class="-user-balance js-user-balance f-sm-6 f-7 ">
						<div class="-inner-box-wrapper">
							<img class="img-fluid -ic-coin" src="../assets/images/ez-slot-ic-coin.png" alt="customer image">
							<span id="customer-balance" class="js-customer-balance">
					<span class="text-green-lighter">{{getCredit()}}</span>

				</span>
						</div>

						
				<button type="button" id="btn-customer-balance-reload" class="-btn-balance  " @click="reloadBalance()">
					<i :class="{'fas fa-sync-alt f-9':true,'fa-spin':fa_spin}"></i>
				</button>
					</div>
				</div>

					<div class="-deposit-container d-none d-xl-block">
						<a href="#deposit" class="text-white js-account-approve-aware btn -btn-deposit" data-toggle="modal" data-target="#depositModal" @click="checkPromotions()">
							<div class="f-7">ฝากเงิน</div>
						</a>
					</div>

					<div class="-withdraw-container d-none d-xl-block">
						<a href="#withdraw" class="text-white js-account-approve-aware btn -btn-withdraw" data-toggle="modal" data-target="#withdrawModal" @click="moveCreditToWallet()">
							<div class="f-7">ถอนเงิน</div>
						</a>
					</div>
					<div class="-profile-container">
						<a href="#account" data-toggle="modal" data-target="#accountModal" class="-btn-wrapper">
							


				<div class="x-profile-image">
					<img class="img-fluid -profile-image" src="../assets/images/avatar.png" alt="customer image">
				</div>

							<span class="-profile-name">{{user.code}}</span>
						</a>
						<div class="d-xl-none d-block">
							<div :class="{'js-ez-logged-sidebar':true, '-open':this.accountOpen }">

				<div class="x-profile-image">
					<a href="javascript:void(0)" @click="showTabAccount()">
					<img class="img-fluid -profile-image" src="../assets/images/avatar.png" alt="customer image">
          <span class="-profile-name" style="font-size:12px;color:#fff;">{{user.code}}</span>
					</a>
				</div>
							</div>
							<div :class="{'x-menu-account-list-sidebar':true,'-open':this.accountOpen}">
								

				<div class="x-modal-account-menu-mobile ">
    <div class="-modal-profile-mobile d-xl-none d-block">
        <div class="text-right">
            <i class="fas fa-times f-5 js-close-account-sidebar" @click="showTabAccount()"></i>
        </div>

        


<div class="x-profile-image">
    <img class="img-fluid -profile-image" style="width:100px;max-width: 100px;" src="../assets/images/avatar.png" alt="customer image">
</div>

        <div class="-balance-container">
            
<div class="-text-username">
    {{user.mobile}}
</div>
    <div class="-user-balance js-user-balance f-sm-6 f-7 ">
        <div class="-inner-box-wrapper">
           <img class="img-fluid -ic-coin" src="../assets/images/ez-slot-ic-coin.png" alt="customer image">
            <span id="customer-balance" class="js-customer-balance">
    <span class="text-green-lighter">{{getCredit()}}</span>
</span>
        </div>
        
<button type="button" id="btn-customer-balance-reload" class="-btn-balance  " @click="reloadBalance()">
    <i :class="{'fas fa-sync-alt f-9':true,'fa-spin':fa_spin}"></i>
</button>
    </div>
        </div>
    </div>

        
    <div class="-transaction-button-wrapper">
                    
            <a href="javascript:void(0);" class="x-transaction-button-v2 -in-sidebar -deposit js-close-account-sidebar" data-toggle="modal" data-target="#depositModal" @click="checkPromotions()">
                <img class="img-fluid -icon" src="../assets/images/icdeposit.png" alt="ฝากเงิน">
                <div class="-text-wrapper">
                    <span class="-title">ฝากเงิน</span>
                    <span class="-sub-title">Deposit</span>
                </div>
            </a>
                    
            <a href="javascript:void(0);" class="x-transaction-button-v2 -in-sidebar -withdraw js-close-account-sidebar" data-toggle="modal" data-target="#withdrawModal"  @click="moveCreditToWallet()">
                <img class="img-fluid -icon" src="../assets/images/icwithdraw.png" alt="ถอนเงิน">
                <div class="-text-wrapper">
                    <span class="-title">ถอนเงิน</span>
                    <span class="-sub-title">Withdraw</span>
                </div>
            </a>
            </div>


    
    <ul class="navbar-nav">
                                    <li class="nav-item -account-profile">
                    <button type="button" class="nav-link js-close-account-sidebar " data-toggle="modal" data-target="#accountModalMobile">
                        <img class="img-fluid -icon-image" src="../assets/images/ez-slot-ic-menu-user.png" alt="ic-menu-user">
                        <span class="-text-menu">
                            ข้อมูลบัญชี
                        </span>
                    </button>
                </li>
                <!-- <li class="nav-item -account-provider">
                  <a href="https://drive.google.com/uc?id=12VqqjYqg2EKagrAa94L2zOcQ-VsSBbKI&export=download" target="_blank">
                    <button type="button" class="nav-link js-close-account-sidebar ">
                        <img class="img-fluid -icon-image" src="../assets/images/ez-slot-ic-menu-provider.png" alt="ic-menu-provider">
                        <span class="-text-menu">
                            เข้าเล่นผ่านแอพ
                        </span>
                    </button>
                  </a>
                </li> -->
                                                <!-- <li class="nav-item -coupon">
                    <button type="button" class="nav-link js-close-account-sidebar js-account-approve-aware" data-toggle="modal" data-target="#couponModalMobile">
                        <img class="img-fluid -icon-image" src="../assets/images/ez-slot-ic-menu-coupon.png" alt="ic-menu-coupon">
                        <span class="-text-menu">
                            ใช้คูปอง
                        </span>
                    </button>
                </li> -->
                <li class="nav-item -join-promotion">
                    <button type="button" class="nav-link js-close-account-sidebar" @click="gotoPage('promotion','themeSwitcherModal')">
                        <img class="img-fluid -icon-image" src="../assets/images/ez-slot-ic-menu-promotion.png" alt="ic-menu-promotion">
                        <span class="-text-menu">
                            โปรโมชั่นที่เข้าร่วม
                        </span>
                    </button>
                </li>
                <li class="nav-item -join-promotion">
                    <button type="button" class="nav-link js-close-account-sidebar " @click="gotoPage('partner','themeSwitcherModal')">
                        <img class="img-fluid -icon-image" src="../assets/images/partner.png" alt="ic-menu-bonus">
                        <span class="-text-menu">
                            พันธมิตร
                        </span>
                    </button>
                </li>
                <li class="nav-item -join-promotion">
                    <button type="button" class="nav-link js-close-account-sidebar " @click="gotoPage('cashback','themeSwitcherModal')">
                        <img class="img-fluid -icon-image" src="../assets/images/cashback.png" alt="ic-menu-bonus">
                        <span class="-text-menu">
                            คืนยอดเล่น
                        </span>
                    </button>
                </li>
                 <li class="nav-item -join-promotion">
                    <button type="button" class="nav-link js-close-account-sidebar " @click="gotoPage('continue','themeSwitcherModal')">
                        <img class="img-fluid -icon-image" src="../assets/images/continue.png" alt="ic-menu-bonus">
                        <span class="-text-menu">
                            ฝากต่อเนื่อง
                        </span>
                    </button>
                </li>
                <li class="nav-item -account-provider">
                    <button type="button" class="nav-link js-close-account-sidebar " @click="gotoPage('rewards','themeSwitcherModal')">
                        <img class="img-fluid -icon-image" src="../assets/images/redeem-rewards.png" alt="ic-menu-provider">
                        <span class="-text-menu">
                            แลกของรางวัล
                        </span>
                    </button>
                </li>
                <li class="nav-item -account-provider">
                    <button type="button" class="nav-link js-close-account-sidebar " @click="gotoPage('history','themeSwitcherModal')">
                        <img class="img-fluid -icon-image" src="../assets/images/history.png" alt="ic-menu-provider">
                        <span class="-text-menu">
                            ประวัติธุรกรรม
                        </span>
                    </button>
                </li>
                                                <li class="nav-item -logout">
                    <a href="javascript:void(0)" @click="confirmLogout" class="nav-link js-require-confirm" data-title="ต้องการออกจากระบบ ?">
                        <img class="img-fluid -icon-image" src="../assets/images/ez-slot-ic-menu-logout.png" alt="ic-menu-logout">
                        <span class="-text-menu">
                        ออกจากระบบ
                    </span></a>
                </li>
                        </ul>
</div>
								<div class="-overlay"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
    	</div>
    	</nav>
			

		<resetPasswordModal></resetPasswordModal>

		<div class="x-modal modal -v2 -with-half-size" id="loginModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".js-modal-content" data-ajax-modal-always-reload="true">
			<div class="modal-dialog -modal-size  -dialog-in-tab -register-index-dialog" role="document" style="padding-top: 100px; height: 100%;">
				<div class="modal-content -modal-content">
						<button type="button" class="close f-1 -in-tab" data-dismiss="modal" aria-label="Close">
							<i class="fas fa-times"></i>
						</button>
						<div class="x-modal-account-security-tabs -v2">
							<button type="button" class="-btn -register js-modal-account-security-tab-button" @click="showModal('loginModal','registerModal')">
								สมัครสมาชิก
							</button>
							<button type="button" class="-btn -login js-modal-account-security-tab-button -active" data-modal-target="#loginModal">
								เข้าสู่ระบบ
							</button>
						</div>

			<div class="modal-body -modal-body" style="top: 0px;">
				<div class="x-modal-separator-container x-login-form">
			<div class="-top ">
						<div data-animatable="fadeInModal" class="animated fadeInModal">
					<div class="-img-container text-center">
				<img class="-ic-login img-fluid lazyloaded" alt="login" width="60" height="60" data-src="../assets/images/ez-slot-ic_login.png?v=1" src="../assets/images/ez-slot-ic_login.png?v=1">
								
					</div>
					<form @submit.prevent="login" class="js-login-form">
						<div class="-x-input-icon mt-3 mb-3 flex-column">
		
				<img class="-icon img-fluid lazyloaded" alt="login_mobile" width="15" height="15" data-src="../assets/images/ez-slot-ic_phone.png?v=1" src="../assets/images/ez-slot-ic_phone.png?v=1">
								<input type="number" id="login_mobile" v-model="login_mobile" inputmode="text" name="username" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10" class="form-control x-form-control" placeholder="เบอร์โทรศัพท์">
						</div>
						<div class="-x-input-icon flex-column">
		
				<img class="-icon img-fluid lazyloaded" alt="password" width="15" height="15" data-src="../assets/images/ez-slot-ic-lock-input.png?v=1" src="../assets/images/ez-slot-ic-lock-input.png?v=1">
								<input type="password" id="login_password" v-model="login_password" class="form-control x-form-control" placeholder="รหัสผ่าน">
						</div>
						<div class="x-reset-pw-text-container -v2">
							<a href="#resetPassword" data-dismiss="modal" data-toggle="modal" data-target="#resetPasswordModal">
								<u>ลืมรหัสผ่าน</u>
							</a>
						</div>

						<div class="text-center">
							<button type="submit" class="btn btn-primary -submit">
								<span>เข้าสู่ระบบ</span>
							</button>
						</div>
					</form>
				</div>
				</div>
		<div class="-bottom ">
						<div data-animatable="fadeInModal" class="animated fadeInModal">
									
										<div class="x-admin-contact ">
							<span class="x-text-with-link-component">
		<label class="-text-message ">พบปัญหา</label>
		<a href="https://lin.ee/kfBXKx6" class="-link-message " target="_blank" rel="noopener">
			<u>ติดต่อฝ่ายบริการลูกค้า</u>
		</a>
	</span>
						</div>
								</div>
				</div>
	</div>
					</div>
				</div>
			</div>
		</div>

		
		<div class="x-modal modal -v2 -with-half-size show" id="registerModal" tabindex="-1" role="dialog" data-loading-container=".modal-body" data-container="#registerModal">
			<div class="modal-dialog -modal-size  -dialog-in-tab -register-index-dialog" role="document" style="padding-top: 100px; height: 100%;">
    <div class="modal-content -modal-content">
                    <button type="button" class="close f-1 -in-tab" data-dismiss="modal" aria-label="Close">
                <i class="fas fa-times"></i>
            </button>
                        <div class="x-modal-account-security-tabs -v2">
    <button type="button" class="-btn -register js-modal-account-security-tab-button -active" data-modal-target="#registerModal">
        สมัครสมาชิก
    </button>
    <button type="button" class="-btn -login js-modal-account-security-tab-button" @click="showModal('registerModal','loginModal')">
        เข้าสู่ระบบ
    </button>
</div>
                    <div class="modal-body -modal-body" style="top: 0px;">
                                <div class="x-form-register -register mt-0">
                <div class="row -animatable-container">
                    <div class="col order-1 text-center pr-lg-0 mx-auto js-slide-term-and-condition-content x-slide-left-content x-slide-left-content-term -hide">
                        <div class="-fake-inner-body">
                            <div class="-term-and-condition-content js-term-and-condition">
                                <div class="x-term-and-condition">
								<div class="text-center d-lg-none">
									<a href="#close-term-and-condition" class="js-get-term-and-condition btn -submit btn-primary my-0 my-lg-3 f-5 f-lg-6">
										<span>ย้อนกลับ</span>
									</a>
								</div>
								</div>
                            </div>
                        </div>
                    </div>

                    <div data-animatable="fadeInRegister" data-offset="0" class="col order-lg-2 order-0 -form animated fadeInRegister">
                        <div class="x-modal-separator-container ">
    <div class="-top ">
                <div class="-fake-inner-body">
            
			<div class="x-step-register">
				<div class="px-0 m-auto -container-wrapper">
					<div :class="{ '-step-box-outer': true, 'step-active': step==1 || step ==4 }"></div>
					<div :class="{ '-step-box-outer': true, 'step-active': step==2 || step ==4 }"></div>
					<div :class="{ '-step-box-outer': true, 'step-active': step==3 || step ==4 }"></div>
				</div>
			</div>

            <div class="-img-container">
                    <picture><source type="image/webp" :srcset="(step==4)?require('../assets/images/success.png'):require('../assets/images/ic_register.webp')"><source type="image/png?v=1" :srcset="(step==4)?require('../assets/images/success.png'):require('../assets/images/ic_register.png')"><img alt="สมัครสมาชิก" class="img-fluid -ic-register" width="80" height="80" :src="(step==4)?require('../assets/images/success.png'):require('../assets/images/ic_register.png')"></picture>
            </div>
            <template v-if="step==1">
				<div class="-x-input-icon mb-3 text-center">
					<img class="-icon" alt="Winner88" width="15" height="15" src="../assets/images/ic_phone.png?v=1">
					<input type="number" v-model="mobile" id="mobile" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10" class="x-form-control form-control" placeholder="เบอร์โทรศัพท์" aria-describedby="basic-addon1">
				</div>
				<div class="m-auto -term-and-condition-check-box">
				</div>
				<div class="text-center">
					<button type="button" class="btn  -submit js-submit-accept-term btn-primary mt-lg-3 mt-0" @click="checkPhone()">
						ยืนยัน
					</button>
				</div>
			</template>
			<template v-else-if="step==2">
				<div class="-x-input-icon mb-3 flex-column">
                        <img alt="สมัครสมาชิกเว็บพนันออนไลน์ แทงบอลออนไลน์" class="-icon" width="15" height="15" src="../assets/images/ic-lock-input.png?v=1">
                    <div class="x-password-toggler">
						<input :type="p1" v-model="password" required="required" class="x-form-control form-control" placeholder="ตั้งรหัสผ่าน 6 ตัวอักษรขึ้นไป" autofocus="autofocus" autocomplete="off">
						<i class="-ic fas fa-eye" @click="showpassword('password')"></i>
						<i class="-ic fas fa-eye-slash" @click="hidepassword('password')"></i>
					</div>
                </div>
                <div class="-x-input-icon flex-column">
                    <img alt="สมัครสมาชิกเว็บพนันออนไลน์ แทงบอลออนไลน์" class="-icon" width="15" height="15" src="../assets/images/ic-lock-input.png?v=1">
                    <div class="x-password-toggler">
                        <input :type="p2" required="required" v-model="password2" class="x-form-control form-control" placeholder="พิมพ์รหัสผ่านอีกครั้ง" autocomplete="off">
                        <i class="-ic fas fa-eye" @click="showpassword('password2')"></i>
    					<i class="-ic fas fa-eye-slash" @click="hidepassword('password2')"></i>
					</div>
                </div>
				<div class="m-auto -term-and-condition-check-box">
				</div>
				<div class="text-center">
					<button type="button" class="btn  -submit js-submit-accept-term btn-primary mt-lg-3 mt-0" @click="checkPassword()">
						ยืนยัน
					</button>
				</div>
			</template>
			<template v-else-if="step==3">
				<div id="card-bank-info">
					<div class="text-center">
						<div class="my-3 js-bank-select-container">
							<div class="x-bank-choices-type ">
								<div class="-outer-wrapper">
                  <input type="radio" class="-input-radio" id="bank-acc-51654879238" name="customer_bank_account[bank]" value="1">
									<label class="-label" for="bank-acc-51654879238">
                    <img class="-logo"  src="../assets/images/bank_register/truewallet.jpg" alt="" @click="selectBank('truewallet')">
                  <i class="fas fa-check"></i>
									</label>
									<input type="radio" class="-input-radio" id="bank-acc-61654879238" name="customer_bank_account[bank]" value="6">
									<label class="-label" for="bank-acc-61654879238">
																	<img class="-logo"  src="../assets/images/bank_register/scb.png" alt="" @click="selectBank('scb')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-71654879238" name="customer_bank_account[bank]" value="7">
									<label class="-label" for="bank-acc-71654879238">
																	<img class="-logo" src="../assets/images/bank_register/kbank.png" alt="" @click="selectBank('kbank')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-81654879238" name="customer_bank_account[bank]" value="8">
									<label class="-label" for="bank-acc-81654879238">
																	<img class="-logo" src="../assets/images/bank_register/bangkok.png" alt="" @click="selectBank('bankokbank')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-91654879238" name="customer_bank_account[bank]" value="9">
									<label class="-label" for="bank-acc-91654879238">
																	<img class="-logo" src="../assets/images/bank_register/bay.png" alt="" @click="selectBank('krungsri')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-101654879238" name="customer_bank_account[bank]" value="10">
									<label class="-label" for="bank-acc-101654879238">
																	<img class="-logo" src="../assets/images/bank_register/k.png" alt="" @click="selectBank('kiatnakin')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-111654879238" name="customer_bank_account[bank]" value="11">
									<label class="-label" for="bank-acc-111654879238">
																	<img class="-logo" src="../assets/images/bank_register/cimb.png" alt="" @click="selectBank('cimb')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-121654879238" name="customer_bank_account[bank]" value="12">
									<label class="-label" for="bank-acc-121654879238">
																	<img class="-logo" src="../assets/images/bank_register/ttb.png" alt="" @click="selectBank('ttb')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-141654879238" name="customer_bank_account[bank]" value="14">
									<label class="-label" for="bank-acc-141654879238">
																	<img class="-logo" src="../assets/images/bank_register/uob.png" alt="" @click="selectBank('uob')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-151654879238" name="customer_bank_account[bank]" value="15">
									<label class="-label" for="bank-acc-151654879238">
																	<img class="-logo" src="../assets/images/bank_register/ktb.png" alt="" @click="selectBank('ktb')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-161654879238" name="customer_bank_account[bank]" value="16">
									<label class="-label" for="bank-acc-161654879238">
																	<img class="-logo" src="../assets/images/bank_register/a.png" alt="" @click="selectBank('gsb')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-171654879238" name="customer_bank_account[bank]" value="17">
									<label class="-label" for="bank-acc-171654879238">
																	<img class="-logo" src="../assets/images/bank_register/baac.png" alt="" @click="selectBank('baac')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-181654879238" name="customer_bank_account[bank]" value="18">
									<label class="-label" for="bank-acc-181654879238">
																	<img class="-logo" src="../assets/images/bank_register/stand.png" alt="" @click="selectBank('standard')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-251654879238" name="customer_bank_account[bank]" value="25">
									<label class="-label" for="bank-acc-251654879238">
																	<img class="-logo" src="../assets/images/bank_register/lh.png" alt="" @click="selectBank('lh')">
																<i class="fas fa-check"></i>
									</label>
													<input type="radio" class="-input-radio" id="bank-acc-271654879238" name="customer_bank_account[bank]" value="27">
									<label class="-label" for="bank-acc-271654879238">
																	<img class="-logo" src="../assets/images/bank_register/tos.png" alt="" @click="selectBank('ghbank')">
																<i class="fas fa-check"></i>
									</label>
                            	</div>
        					</div>
    					</div>

						<div class="js-bank-number-and-name-container">
							<div class="-x-input-icon mb-3 flex-column" v-if="!showMobile">
								<img src="../assets/images/ic_library_book.png" class="-icon" alt="" width="14">
								<input type="number" v-model="account_no" required="required" class="x-form-control form-control" placeholder="กรอกเลขบัญชี" autofocus="autofocus" inputmode="text">
							</div>
							<div class="-x-input-icon mb-3 flex-column">
								<img src="../assets/images/ic_user_circle.png" class="-icon" alt="" width="16">
								<input type="text"  required="required" class="x-form-control form-control" v-model="firstname" placeholder="กรอก ชื่อ">
							</div>
							<div class="-x-input-icon mb-3 flex-column">
								<img src="../assets/images/ic_user_circle.png" class="-icon" alt="" width="16">
								<input type="text"  required="required" class="x-form-control form-control" v-model="lastname" placeholder="กรอก นามสกุล">
							</div>
							<div class="-text-hint col-sm-10 m-auto d-block">กรอกข้อมูลให้ครบถ้วนและถูกต้องมิฉะนั้นท่านจะไม่สามารถถอนเงินได้</div>

							<div class="text-center">
								<button type="button" class="btn  -submit btn-primary my-lg-3 my-0" @click="register()">
									ยืนยัน
								</button>
							</div>
						</div>
					</div>
                </div>
			</template>
			<template v-if="step==4">
				<div class="-x-input-icon mb-3 text-center" style="justify-content: center;">
					<h2 class="-game-title h5 d-lg-none">
						สมัครสมาชิกสำเร็จแล้ว
					</h2>
				</div>
				<div class="-x-input-icon mb-3 text-center">
					<input type="text" :value="'Username: '+mobile" readonly class="x-form-control form-control" placeholder="เบอร์โทรศัพท์" aria-describedby="basic-addon1">
				</div>
				<div class="-x-input-icon mb-3 text-center">
					<input type="text" :value="'Password: '+password"  readonly class="x-form-control form-control" placeholder="Password" aria-describedby="basic-addon1">
				</div>
				<div class="m-auto -term-and-condition-check-box">
				</div>
				<div class="text-center">
					<button type="button" class="btn btn-primary -submit" @click="showModal('registerModal','loginModal')">
						เข้าสู่ระบบ
					</button>
				</div>

			</template>
        </div>
        </div>
    <div class="-bottom ">
                        
                    <div class="x-admin-contact text-center ">
                <span class="x-text-with-link-component">
     <label class="-text-message ">พบปัญหา</label>
     <a href="https://lin.ee/kfBXKx6" class="-link-message " target="_blank" rel="noopener">
        <u>ติดต่อฝ่ายบริการลูกค้า</u>
    </a>
</span>
            </div>
                </div>
</div>
                    </div>
                </div>
            </div>
                    </div>
    </div>
			</div>
		</div>


	<div class="x-modal modal  x-theme-switcher-v2" id="themeSwitcherModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".js-modal-content" data-ajax-modal-always-reload="true">
                            <div class="modal-dialog -modal-size" role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                                <div class="modal-body -modal-body">
    
    <div class="-theme-switcher-container">
                <div class="-inner-header-section">
            <a class="-link-wrapper" href="/">
                <img class="-logo" src="../assets/images/logo.png" alt="Winner88 รวมคาสิโนออนไลน์ บาคาร่าสด สล็อต กีฬา และเครดิตฟรี">
            </a>
        </div>
    
        <div class="-inner-top-body-section">
          <template v-if="user.key">
            <div class="col-6 -wrapper-box">
              <button type="button" class="btn -btn-item x-transaction-button-v2 -deposit -top-btn -horizontal" data-toggle="modal" data-dismiss="modal" data-target="#depositModal"  @click="checkPromotions()">
                <img alt="Winner88 รูปไอคอนฝากเงิน" class="img-fluid -icon-image" src="../assets/images/icdeposit.png">
                <div class="-typo-wrapper">
                  <div class="-title">ฝากเงิน</div>
                  <div class="-sub-title">Deposit</div>
                </div>
              </button>
            </div>
            <div class="col-6 -wrapper-box">
              <button type="button" class="btn -btn-item x-transaction-button-v2 -withdraw -top-btn -horizontal" data-toggle="modal" data-dismiss="modal" data-target="#withdrawModal"  @click="moveCreditToWallet()">
                <img alt="Winner88 รูปไอคอนถอนเงิน" class="img-fluid -icon-image" src="../assets/images/icwithdraw.png">
                  <div class="-typo-wrapper">
                    <div class="-title">ถอนเงิน</div>
                    <div class="-sub-title">Withdraw</div>
                  </div>
              </button>
            </div>
          </template>
          <template v-else>
            <div class="col-6 -wrapper-box">
              <button type="button" class="btn -btn-item -top-btn -login-btn " data-toggle="modal" data-dismiss="modal" data-target="#registerModal">
                  <img alt="Winner88 รูปไอคอนสมัครสมาชิก" class="img-fluid -icon-image" src="../assets/images/ez-theme-switcher-ic-register.png?v=1">
                      <div class="-typo-wrapper">
                  <div class="-typo ">สมัครเลย</div>
                              </div>
              </button>
            </div>
            <div class="col-6 -wrapper-box">
              <button type="button" class="btn -btn-item -top-btn -login-btn " data-toggle="modal" data-dismiss="modal" data-target="#loginModal">
                  <img alt="Winner88 รูปไอคอนเข้าสู่ระบบ" class="img-fluid -icon-image" src="../assets/images/ez-theme-switcher-ic-login.png?v=1">
                  <div class="-typo-wrapper">
                    <div class="-typo ">เข้าสู่ระบบ</div>
                  </div>
              </button>
            </div>
          </template>
          
                    </div>
    
                
        <div class="-inner-center-body-section">
                            <div class="col-6 -wrapper-box">
    
			<a href="javascript:void(0)" @click="gotoPage('event','themeSwitcherModal')" class="btn -btn-item -event-button -menu-center -horizontal">
				<img alt="Winner88 รูปไอคอนสิทธิพิเศษ" class="img-fluid -icon-image" src="../assets/images/ez-theme-switcher-ic-event.png?v=1">
                    <div class="-typo-wrapper">
                <div class="-typo ">สิทธิพิเศษ</div>
                            </div>
			</a>
                </div>
                            <div class="col-6 -wrapper-box">
			<a href="javascript:void(0)" @click="gotoPage('promotion','themeSwitcherModal')" class="btn -btn-item -event-button -menu-center -horizontal">
					<img alt="Winner88 รูปไอคอนโปรโมชั่น" class="img-fluid -icon-image" src="../assets/images/ez-theme-switcher-ic-promotion.png?v=1">
                    <div class="-typo-wrapper">
                	<div class="-typo ">โปรโมชั่น</div>
                    </div>
			</a>
                </div>
                <div class="col-6 -wrapper-box">
                    <a href="javascript:void(0)" @click="gotoPage('rewards','themeSwitcherModal')" class="btn -btn-item -event-button -menu-center -horizontal">
                      <img alt="Winner88 แลกของรางวัล" class="img-fluid -icon-image" src="../assets/images/redeem-rewards.png?v=1">
                                  <div class="-typo-wrapper">
                              <div class="-typo ">แลกของรางวัล</div>
                                          </div>
                    </a>
                  </div>
                <template v-if="user.key">
                  <div class="col-6 -wrapper-box">
                    <a href="javascript:void(0)" @click="gotoPage('cashback','themeSwitcherModal')" class="btn -btn-item -event-button -menu-center -horizontal">
                      <img alt="Winner88 คืนยอด" class="img-fluid -icon-image" src="../assets/images/cashback.png?v=1">
                                  <div class="-typo-wrapper">
                              <div class="-typo ">คืนยอดเล่น</div>
                                          </div>
                    </a>
                  </div>
                  <div class="col-6 -wrapper-box">
                    <a href="javascript:void(0)" @click="gotoPage('continue','themeSwitcherModal')" class="btn -btn-item -event-button -menu-center -horizontal">
                      <img alt="Winner88 ฝากต่อเนื่อง" class="img-fluid -icon-image" src="../assets/images/continue.png?v=1">
                                  <div class="-typo-wrapper">
                              <div class="-typo ">ฝากต่อเนื่อง</div>
                                          </div>
                    </a>
                  </div>
                  <div class="col-6 -wrapper-box">
                    <a href="javascript:void(0)" @click="gotoPage('partner','themeSwitcherModal')" class="btn -btn-item -event-button -menu-center -horizontal">
                      <img alt="Winner88 ฝากต่อเนื่อง" class="img-fluid -icon-image" src="../assets/images/partner.png?v=1">
                                  <div class="-typo-wrapper">
                              <div class="-typo ">พันธมิตร</div>
                                          </div>
                    </a>
                  </div>
                  <div class="col-6 -wrapper-box">
                    <a href="javascript:void(0)" @click="gotoPage('history','themeSwitcherModal')" class="btn -btn-item -event-button -menu-center -horizontal">
                      <img alt="Winner88 ประวัติธุรกรรม" class="img-fluid -icon-image" src="../assets/images/history.png?v=1">
                                  <div class="-typo-wrapper">
                              <div class="-typo ">ประวัติธุรกรรม</div>
                                          </div>
                    </a>
                  </div>
                </template>
                            <div class="col-6 -wrapper-box">
            <a href="https://lin.ee/kfBXKx6" class="btn -btn-item -line-button -menu-center -horizontal" target="_blank" rel="noopener nofollow">
                <img alt="Winner88 รูปไอคอนดูหนัง" class="img-fluid -icon-image" src="../assets/images/ez-theme-switcher-ic-line.png?v=1">
                                    
                    <div class="-typo-wrapper">
                <div class="-typo ">Line</div>
                            </div>
            
        </a>
    
                </div>
                    </div>
    

    </div>
                </div>
            </div>
        </div>
    </div>
		<div class="x-modal modal -v2 show" id="accountModal" tabindex="-1" role="dialog" data-loading-container=".modal-body" data-ajax-modal-always-reload="true" data-container="#accountModal" aria-modal="true"><div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -modal-big -modal-main-account" role="document">
    <div class="modal-content -modal-content">
                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                <i class="fas fa-times"></i>
            </button>
                                <div class="modal-body -modal-body">
                        <div class="x-modal-account-menu">
    <ul class="navbar-nav">
        <li class="nav-item -account-profile active">
            <button type="button" class="nav-link js-close-account-sidebar" data-active-menu="-account-profile">
                <img class="img-fluid -icon-image" src="../assets/images/ez-slot-ic-menu-user.png" alt="icon user">
                <div class="-text-menu">
                    ข้อมูลบัญชี
                </div>
            </button>
        </li>
        <!-- <li class="nav-item -account-provider ">
          <a href="https://drive.google.com/uc?id=12VqqjYqg2EKagrAa94L2zOcQ-VsSBbKI&export=download" target="_blank">
            <button type="button" class="nav-link js-close-account-sidebar">
                <img class="img-fluid -icon-image" src="../assets/images/ez-slot-ic-menu-provider.png" alt="icon phone">
                <div class="-text-menu">
                    เข้าเล่นผ่านแอพ
                </div>
            </button>
          </a>
        </li> -->
                    <!-- <li class="nav-item -coupon ">
                <button type="button" class="nav-link js-close-account-sidebar js-account-approve-aware" data-ajax-account-modal="/account/_ajax_/coupon-apply" data-container="#accountModal" data-active-menu="-coupon">
                    <img class="img-fluid -icon-image" src="../assets/images/ez-slot-ic-menu-coupon.png" alt="icon coupon">
                    <div class="-text-menu">
                        ใช้คูปอง
                    </div>
                </button>
            </li> -->
        
        <li class="nav-item -join-promotion ">
            <button type="button" class="nav-link js-close-account-sidebar" @click="gotoPage('promotion','accountModal')">
                <img class="img-fluid -icon-image" src="../assets/images/ez-slot-ic-menu-promotion.png" alt="icon promotion">
                <div class="-text-menu">
                    โปรโมชั่นที่เข้าร่วม
                </div>
            </button>
        </li>
        <li class="nav-item -join-promotion ">
            <button type="button" class="nav-link js-close-account-sidebar" @click="gotoPage('event','accountModal')">
                <img class="img-fluid -icon-image" src="../assets/images/ez-theme-switcher-ic-event.png?v=1" alt="icon promotion">
                <div class="-text-menu">
                    สิทธิพิเศษ
                </div>
            </button>
        </li>
            <li class="nav-item -join-promotion ">
                <button type="button" class="nav-link js-close-account-sidebar" @click="gotoPage('partner','accountModal')">
                    <img class="img-fluid -icon-image" src="../assets/images/partner.png" alt="icon bonus from promotion return by user" width="28" height="27">
                    <span class="-text-menu">
                        พันธมิตร
                    </span>
                </button>
            </li>
            <li class="nav-item -join-promotion ">
                <button type="button" class="nav-link js-close-account-sidebar" @click="gotoPage('cashback','accountModal')">
                    <img class="img-fluid -icon-image" src="../assets/images/cashback.png" alt="icon bonus from promotion return by user" width="28" height="27">
                    <span class="-text-menu">
                        คืนยอดเล่น
                    </span>
                </button>
            </li>
            <li class="nav-item -join-promotion ">
                <button type="button" class="nav-link js-close-account-sidebar" @click="gotoPage('continue','accountModal')">
                    <img class="img-fluid -icon-image" src="../assets/images/continue.png" alt="icon bonus from promotion return by user" width="28" height="27">
                    <span class="-text-menu">
                        ฝากต่อเนื่อง
                    </span>
                </button>
            </li>
            <li class="nav-item -join-promotion ">
                <button type="button" class="nav-link js-close-account-sidebar" @click="gotoPage('rewards','accountModal')">
                    <img class="img-fluid -icon-image" src="../assets/images/redeem-rewards.png" alt="icon bonus from promotion return by user" width="28" height="27">
                    <span class="-text-menu">
                        แลกของรางวัล
                    </span>
                </button>
            </li>
            <li class="nav-item -join-promotion ">
                <button type="button" class="nav-link js-close-account-sidebar" @click="gotoPage('history','accountModal')">
                    <img class="img-fluid -icon-image" src="../assets/images/history.png" alt="icon bonus from promotion return by user" width="28" height="27">
                    <span class="-text-menu">
                        ประวัติธุรกรรม
                    </span>
                </button>
            </li>
        
        <li class="nav-item js-close-account-sidebar -logout">
            <a href="javascript:void(0)" @click="confirmLogout" class="nav-link js-require-confirm" data-title="ต้องการออกจากระบบ ?">
                <img class="img-fluid -icon-image" src="../assets/images/ez-slot-ic-menu-logout.png" alt="icon logout">
                <div class="-text-menu">
                    ออกจากระบบ
                </div>
            </a>
        </li>
    </ul>
</div>
        <div class="js-profile-account-modal -layout-account">
                    
<div class="x-account-profile -v2">
    <div data-animatable="fadeInModal" class="-profile-container animated fadeInModal">

                    <h3 class="x-title-modal mx-auto text-center ">
    ข้อมูลบัญชี
</h3>
        
        <div class="text-center">
            <div class="my-3">
                


<div class="x-profile-image">
    <img class="img-fluid -profile-image" style="width:100px;max-width: 100px;" src="../assets/images/avatar.png" alt="customer image">
</div>
            </div>

            <div class="my-3">
                <div class="-text-username">Username:  {{user.tel}}</div>
                <a href="#0" class="-link-change-password" data-toggle="collapse" data-target=".js-change-password-collapse"><u>เปลี่ยนรหัสผ่าน</u></a>

                            </div>

            <div class="collapse -change-password-container js-change-password-collapse">
									<div class="js-collapse-content">

									<div class="form-group mt-3">
										<input type="password" v-model="repassword" required="required" placeholder="รหัสผ่านปัจจุบัน" class="x-form-control form-control">
									</div>
									<div class="form-group">
										<input type="password" v-model="repassword1" required="required" placeholder="รหัสผ่านใหม่" class="x-form-control form-control">
									</div>
									<div class="form-group">
										<input type="password" v-model="repassword2" required="required" placeholder="พิมพ์รหัสผ่านใหม่อีกครั้ง" class="x-form-control form-control">
									</div>

									<button type="button" @click="changePassword()" class="btn -submit btn-primary text-center m-auto js-user-confirm-deposit">
										<span>ยืนยัน</span>
									</button>

									</div>
						</div>
        </div>

                    <div class="-bank-info-container">
                            <div class="x-customer-bank-info-container -v2">
    <div class="media m-auto">
        <img v-if="user.bank" :src="require('../assets/images/bank/' + user.bank + '.jpg')" class="-img rounded-circle" width="50" height="50" alt="bank-scb">
        <div class="-content-wrapper">
            <span class="-name">{{user.firstname}} {{user.lastname}}</span>
						<span class="-number">{{user.account_no}}</span>
        </div>
    </div>
</div>
                </div>

            
    <div class="x-admin-contact text-center ">
        <span class="x-text-with-link-component">
     <label class="-text-message ">พบปัญหา</label>
     <a href="https://lin.ee/kfBXKx6" class="-link-message " target="_blank" rel="noopener">
        <u>ติดต่อฝ่ายบริการลูกค้า</u>
    </a>
</span>
    </div>

            <div class="js-has-info"></div>
            </div>
</div>
            </div>

        </div>
    </div>
</div></div>


		<div class="x-modal modal show" id="accountModalMobile" tabindex="-1" role="dialog" data-loading-container=".modal-body" data-container="#accountModalMobile" aria-modal="true">
		<div class="modal-dialog -modal-size  -modal-mobile -account-modal -no-fixed-button" role="document" style="padding-top: 60px;">
			<div class="modal-content -modal-content" style="height:100vh;">
				<button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
					<i class="fas fa-times"></i>
				</button>
				<div class="modal-header -modal-header">
					<div class="x-modal-mobile-header">
						<div class="-header-mobile-container">
							<h3 class="x-title-modal mx-auto text-center d-inline-block">
								ข้อมูลบัญชี
							</h3>
						</div>
					</div>
				</div>
				<div class="modal-body -modal-body" style="top: 76px;">
					<div class="x-account-profile -v2">
						<div data-animatable="fadeInModal" class="-profile-container animated fadeInModal">
							<div class="text-center">
								<div class="my-3">
									<div class="x-profile-image">
										<img class="img-fluid -profile-image" src="../assets/images/avatar.png" alt="customer image" style="width: 150px;height: 150px;">
									</div>
								</div>

								<div class="my-3">
									<div class="-text-username">Username:  {{user.tel}}</div>
									<a href="#0" class="-link-change-password" data-toggle="collapse" data-target=".js-change-password-collapse"><u>เปลี่ยนรหัสผ่าน</u></a>
								</div>

								<div class="-change-password-container js-change-password-collapse collapse" style="">
									<div class="js-collapse-content">

											<div class="form-group mt-3">
												<input type="password" v-model="repassword" required="required" placeholder="รหัสผ่านปัจจุบัน" class="x-form-control form-control">
											</div>
											<div class="form-group">
												<input type="password" v-model="repassword1" required="required" placeholder="รหัสผ่านใหม่" class="x-form-control form-control">
											</div>
											<div class="form-group">
												<input type="password" v-model="repassword2" required="required" placeholder="พิมพ์รหัสผ่านใหม่อีกครั้ง" class="x-form-control form-control">
											</div>

											<button type="button" @click="changePassword()" class="btn btn-block -submit">
												<span>ยืนยัน</span>
											</button>
									</div>
								</div>
							</div>

							<div class="-bank-info-container">
								<div class="x-customer-bank-info-container -v2">
									<div class="media m-auto">
										<img v-if="user.bank" :src="require('../assets/images/bank/' + user.bank + '.jpg')" class="-img rounded-circle" width="50" height="50" alt="bank-scb">
										<div class="-content-wrapper">
											<span class="-name">{{user.firstname}} {{user.lastname}}</span>
											<span class="-number">{{user.account_no}}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="x-admin-contact text-center ">
								<span class="x-text-with-link-component">
								<label class="-text-message ">พบปัญหา</label>
								<a href="https://lin.ee/kfBXKx6" class="-link-message " target="_blank" rel="noopener">
									<u>ติดต่อฝ่ายบริการลูกค้า</u>
								</a>
								</span>
							</div>

							<div class="js-has-info"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>


		<div
			class="x-modal modal -v2 -with-half-size"
			id="withdrawModal"
			tabindex="-1"
			role="dialog"
			aria-hidden="true"
			data-loading-container=".modal-body"
			data-container="#withdrawModal"
     	 >
<div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -modal-mobile -modal-mobile-clean-padding" role="document">
  <div class="modal-content -modal-content">
    <button
      type="button"
      class="close f-1 "
      data-dismiss="modal"
      aria-label="Close"
    >
      <i class="fas fa-times"></i>
    </button>
    <div class="modal-header -modal-header">
      <h3 class="x-title-modal -v2 m-auto">
ถอนเงิน
      </h3>
    </div>
    <div class="modal-body -modal-body" style="top: 0px;">
      <div class="x-withdraw-form -v2">
  <div
    data-animatable="fadeInModal"
    class="-animatable-container animated fadeInModal"
  >
    <div class="text-center d-flex flex-column">
      <div
class="-x-input-icon x-input-operator mb-3 flex-column"
      >
<button
  type="button"
  class="-icon-left -btn-icon js-adjust-amount-by-operator"
  @click="setWithdraw('minus')"
>
  <i class="fas fa-minus-circle"></i>
</button>
<input
  type="text"
  v-model="withdraw"
  :class="{'x-form-control':true,'text-center':true, 'js-withdraw-input-amount':true ,'-no':true,'form-control':true,'is-invalid':(error)?true:false}"
  placeholder="จำนวนเงินที่ต้องการถอน"
  @keypress="isNumber($event)"

/>
						<div class="invalid-feedback " v-if="error">
							<ul class="list-unstyled mb-0"><li>{{error}}</li></ul>
						</div>
<button
  type="button"
  class="-icon-right -btn-icon js-adjust-amount-by-operator"
  @click="setWithdraw('plus')"
>
  <i class="fas fa-plus-circle"></i>
</button>
      </div>
					<!-- <div  v-if="lastbonus.canwithdraw || lastbonus.turncredit">
					<p class="glow" style="font-size:14px;">
						{{lastbonus.canwithdraw}}/{{lastbonus.turncredit}}
					</p>
					</div> -->
      <div
class="x-select-amount js-quick-amount -v2"
data-target-input="#withdraw_amount"
      >
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(100)"
  >
    <span class="-no">100</span>
  </button>
</div>
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(300)"
  >
    <span class="-no">300</span>
  </button>
</div>
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(500)"
  >
    <span class="-no">500</span>
  </button>
</div>
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(1000)"
  >
    <span class="-no">1000</span>
  </button>
</div>
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(2000)"
  >
    <span class="-no">2000</span>
  </button>
</div>
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(5000)"
  >
    <span class="-no">5000</span>
  </button>
</div>
      </div>
    </div>

    <div class="text-center">
      <button
type="button"
class="btn -submit btn-primary my-0 my-lg-3 f-5 f-lg-6"
						@click="withdrawConfirm()"
      >
{{this.canwithdraw}}
      </button>
    </div>
  </div>

  <input
    type="hidden"
    id="withdraw__token"
    name="withdraw[_token]"
    value="bllExR5-OWRJU6oF0VySYE94ynEFuTfZGWIxep2iqUo"
  />
      </div>
    </div>
  </div>
</div>
      	</div>

		<div
			class="x-modal modal -v2 -with-backdrop -with-separator -with-half-size"
			id="depositModal"
			tabindex="-1"
			role="dialog"
			aria-hidden="true"
			data-loading-container=".modal-body"
			data-container="#depositModal"
      >
<div class="modal-dialog -modal-size modal-dialog-centered -modal-deposit -modal-mobile" role="document">
    <div class="modal-content -modal-content">
      <button
		type="button"
		class="close f-1 "
		data-dismiss="modal"
		aria-label="Close"
      >
<i class="fas fa-times"></i>
      </button>
      <div class="modal-body -modal-body" style="top: 0px;">
<div class="x-pending -v2 ">
  <div
    class="js-content text-center -animated-wrapper animated fadeInModal"
    data-animatable="fadeInModal"
  >
    <div class="-fake-bg-top-wrapper" style="padding: 1rem 1rem 1rem;">
      <h3
class="x-title-modal -v2 mx-auto text-center d-inline-block"
      >
ฝากเงิน
      </h3>
    </div>
    <div class="">
      <div class="x-modal-separator-container ">
<div class="-top ">
  <div class="mt-3 -spacer" v-if="bank && bank.scb">
    <div class="x-deposit-bank-info-container -v2">
      <div class="media m-auto">
<img
  src="../assets/images/bank/scb.jpg"
  class="-img rounded-circle"
  width="50"
  height="50"
  alt="bank-bay"
/>
<div class="-content-wrapper">
  <span class="-name">ธนาคารไทยพาณิชย์</span>
  <span class="-own-name"
    >{{bank.scb.firstname}} {{bank.scb.lastname}}</span
  >
  <span id="depositBankNumber" class="-number"
    >{{bank.scb.accountno}}</span
  >
</div>
<div class="-copy-wrapper">
  <a
    href="javascript:void(0);"
    class="btn -btn js-copy-to-clipboard"
    v-clipboard:copy="bank.scb.accountno.replace(/-/g,'')"
		v-clipboard:success="clipboardSuccessHandler"
    >คัดลอก</a
  >
</div>
      </div>
    </div>
  </div>

  <!-- <div class="mt-3 -spacer" v-if="bank && bank.ktb">
    <div class="x-deposit-bank-info-container -v2">
      <div class="media m-auto">
<img
  src="../assets/images/bank/ktb.jpg"
  class="-img rounded-circle"
  width="50"
  height="50"
  alt="bank-bay"
/>
<div class="-content-wrapper">
  <span class="-name">ธนาคารกรุงไทย</span>
  <span class="-own-name"
    >{{bank.ktb.firstname}} {{bank.ktb.lastname}}</span
  >
  <span id="depositBankNumber" class="-number"
    >{{bank.ktb.accountno}}</span
  >
</div>
<div class="-copy-wrapper">
  <a
    href="javascript:void(0);"
    class="btn -btn js-copy-to-clipboard"
    v-clipboard:copy="bank.ktb.accountno.replace(/-/g,'')"
		v-clipboard:success="clipboardSuccessHandler"
    >คัดลอก</a
  >
</div>
      </div>
    </div>
  </div> -->

						  <div class="mt-3 -spacer" v-if="bank && bank.truewallet">
    <div class="x-deposit-bank-info-container -v2">
      <div class="media m-auto">
<img
  src="../assets/images/bank/tmw.png"
  class="-img rounded-circle"
  width="50"
  height="50"
  alt="bank-bay"
/>
<div class="-content-wrapper">
  <span class="-name">ทรูมันนี่ วอลเล็ท</span>
  <span class="-own-name"
    >{{bank.truewallet.firstname}}  {{bank.truewallet.lastname}}</span
  >
  <span id="depositBankNumber2" class="-number"
    >{{bank.truewallet.mobile}}</span
  >
</div>
<div class="-copy-wrapper">
  <a
    href="javascript:void(0);"
	v-clipboard:copy="bank.truewallet.mobile.replace(/-/g,'')"
	v-clipboard:success="clipboardSuccessHandler"
    class="btn -btn js-copy-to-clipboard"
    >คัดลอก</a
  >
</div>
      </div>
    </div>
  </div>
							<div class="-spacer">
								<div class="x-danger-zone -v2 ">
								<span
									>กรุณาใช้เลขบัญชีที่สมัครโอนเข้ามาเท่านั้น</span
								>
								</div>
							</div>
						  	<div class="-spacer">
								<hr class="-liner">
							</div>
							

							<div class="x-deposit-promotion-outer-container js-scroll-ltr -fade -on-left -on-right" v-if="promotions && promotions.name">
								<div><p class="-title" style="color:#fff;font-family: 'Kanit', sans-serif; font-size: 12px; padding-bottom:10px;">หากต้องการรับโปร โปรดกดรับโปรทุกครั้งก่อนโอนเงินนะคะ</p></div>
								<div class="x-deposit-promotion -v2 -slide pt-0" data-scroll-booster-container=".x-deposit-promotion-outer-container" data-scroll-booster-content=".x-deposit-promotion" style="transform: translate(0px, 0px);">
									<div :class="{'-promotion-box-wrapper':true,'-active':this.gotpro}" @click="receivePromotion()">
										<button type="button" :class="{'btn -promotion-box-apply-btn js-promotion-apply':true,'-active':this.gotpro}">
											<img src="../assets/images/ic-gift.png" class="-img" :alt="promotions.name" width="26" height="26">
											<span class="-title" style="font-size: 12px;">{{promotions.name}}</span>
										</button>
										<div class="mid">
										 <label class="rocker rocker-small" style="box-sizing: inherit;margin:0;padding:0;">
											<input type="checkbox" v-model="this.gotpro" @click="receivePromotion()">
											<span class="switch-left">รับ</span>
											<span class="switch-right">ไม่รับ</span>
										</label>
										</div>
										<!-- <a href="javascript:void(0)" :class="{'-promotion-box-cancel-btn js-cancel-promotion':true,'-show':this.gotpro}">
											<i class="fas fa-times"></i>
										</a> -->
									</div>
                  
									<!-- <div class="-promotion-box-wrapper -active">
										<button type="button" class="btn -promotion-box-apply-btn js-promotion-apply -active" data-url="/promotion/27/apply" data-type="deposit" data-display-slide-mode="true">
											<img src="../assets/images/ic-gift.png" class="-img" alt="รับเพิ่ม 2% ทุกบิลฝาก" width="26" height="26">
											<span class="-title" style="font-size: 12px;">รับเพิ่ม 2% ทุกบิลฝาก</span>
										</button>
										<a href="javascript:void(0)" class="-promotion-box-cancel-btn js-cancel-promotion -show" data-url="/promotion-active/cancel" data-display-slide-mode="true">
											<i class="fas fa-times"></i>
										</a>
									</div>

									<div class="-promotion-box-wrapper -active">
										<button type="button" class="btn -promotion-box-apply-btn js-promotion-apply -active" data-url="/promotion/27/apply" data-type="deposit" data-display-slide-mode="true">
											<img src="../assets/images/ic-gift.png" class="-img" alt="รับเพิ่ม 2% ทุกบิลฝาก" width="26" height="26">
											<span class="-title" style="font-size: 12px;">รับเพิ่ม 2% ทุกบิลฝาก</span>
										</button>
										<a href="javascript:void(0)" class="-promotion-box-cancel-btn js-cancel-promotion -show" data-url="/promotion-active/cancel" data-display-slide-mode="true">
											<i class="fas fa-times"></i>
										</a>
									</div> -->
    							</div>
 							</div>
							<div><p class="-description" style="font-family: 'Kanit', sans-serif;">ฝาก ไม่รับโบนัส ถอนได้ทันทีไม่มีเงื่อนไข</p></div>
              <div class="-spacer" style="cursor:pointer" @click="openBrowseFile()">
								<input type="file" id="my_file" accept="image/*" @change="uploadSlip">
								<div class="x-danger-zone -v2" style="background:#f48d1f;color:#fff;">
								<span style="font-size:15px;">หากเงินไม่เข้าภายใน 5 นาที อัพโหลดสลิปที่นี่!!</span>
								</div>
							</div>

</div>
<div class="-bottom ">
  <div class="my-3 -spacer">
    <button
      type="button"
      class="btn -submit btn-primary text-center m-auto js-user-confirm-deposit"
      data-dismiss="modal"
      aria-label="Close"
    >
      ปิดหน้าต่าง
    </button>
  </div>
</div>
      </div>
    </div>
  </div>
</div>
      </div>
    </div>
</div>
      	</div>

<!-- Promotion -->
		  <div data-loading-container=".modal-body" data-container=".promotion-detail-modal-27" data-ajax-modal-always-reload="true" tabindex="-1" class="modal x-modal -promotion-detail-modal promotion-detail-modal-27 show" aria-modal="true" :style="`display: ${(promotion && promotion.name)?`block`:'none'}; padding-right: 8px;`"><div class="modal-dialog -modal-size  " role="document" style="padding-top: 152.8px;">
    <div class="modal-content -modal-content" :style="{ 'background-image': `url(${require('../assets/images/Shiba-BG.jpg')})`}">
                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close" @click="closePromotion()">
                <i class="fas fa-times"></i>
            </button>
                                <div class="modal-body -modal-body" style="top: 0px;">
                            
        <div class="container">
            <div class="row">
                <div class="col-12 mt-4">
                    <div class="x-page-title-component ">
    <div class="-inner-wrapper">
        <h1 class="-title">โปรโมชั่น</h1>
            </div>
</div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column">
                            <div class="-real-content">
    <div class="x-card card -card-promotion-detail ">
                                            
            <div class="card-body">
                <div class="-img-container">
                    <img :src="promotion.image" :alt="promotion.name" class="-img-promotion img-fluid">
                </div>
                <div class="x-promotion-content"><h2>{{promotion.name}}</h2>
<p><br>
<div v-html="nl2br(promotion.detail)" style="font-size:15px;font-family: 'Kanit', sans-serif;">
</div>
<br>
<!-- <a href="/term-and-condition">เงื่อนไขและกติกาพื้นฐานจะถูกนำมาใช้กับโปรโมชั่นนี้</a> -->
</p></div>
            </div>
                            <div class="card-footer">
                    <!-- <button class="btn -btn -get-promotion-btn js-promotion-apply " data-toggle="modal" data-target="#depositModal" data-promotion-target="promotion-detail-modal-27" data-type="deposit" data-dismiss="modal" data-url="/promotion/27/apply">
                        <span>รับโปรโมชั่น</span>
                    </button> -->
                </div>
                        </div>
                </div>
            
                                                        
                                    <div class="mx-3 mb-3">
                        <div class="x-admin-contact -no-fixed">
                            <span class="x-text-with-link-component" style="font-size:15px;font-family: 'Kanit', sans-serif;">
     <label class="-text-message ">ติดปัญหา&nbsp;</label>&nbsp;
     <a href="https://lin.ee/kfBXKx6" class="-link-message " target="_blank" rel="noopener">
        <u>ติดต่อฝ่ายบริการลูกค้า</u>
    </a>
</span>
                        </div>
                    </div>
                                    </div>
            </div>
    </div>
</div></div>
	</header>
</template>

<script>
import queryString from 'querystring'
import firebase from 'firebase/app'
import 'firebase/database'
import router from './../router'
import moment from 'moment'
const audio = new Audio(require('../assets/money.mp3'))
const audio2 = new Audio(require('../assets/moneyout.mp3'))
const axios = require('axios').default
const qs = require('qs')
export default {
  name: "Header",
  props: {
   mainMenu: String
  },
  components: {
    resetPasswordModal: () => import('./modal/resetPasswordModal.vue'),
  },
  mounted () {
    this.$store.commit('user/SET_LOADING', true)
    const user = localStorage.getItem('userData')
    let _user;
    if (user) {
        _user = JSON.parse(user)
        if(!_user.lastlogin || _user.lastlogin !== moment().tz('Asia/Bangkok').format('YYYY-MM-DD')){
    		  _user.lastlogin = moment().tz('Asia/Bangkok').format('YYYY-MM-DD')
    		  //localStorage.setItem('userData', JSON.stringify(_user))
    	  }
    }
    this.$store.dispatch('user/getGames',{user:_user,search:''}).catch(err => { 
      setTimeout(() => {
        this.$store.commit('user/SET_LOADING', false)  
      }, 500);
      console.error(err) 
    }).then(()=>{
      setTimeout(() => {
        this.$store.commit('user/SET_LOADING', false)  
      }, 500);
    });
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        this.addLogin(_user)
        // ...
      } else {
        this.$store.dispatch('user/setUser',null).catch(err => { console.error(err) });
        // User is signed out
        // ...
      }
    });
      // const user = localStorage.getItem('userData');
    // if(user){
    // 	  const _user = JSON.parse(user);
    // 	  if(!_user.lastlogin || _user.lastlogin !== moment().tz('Asia/Bangkok').format('YYYY-MM-DD')){
    // 		  _user.lastlogin = moment().tz('Asia/Bangkok').format('YYYY-MM-DD')
    // 		  localStorage.setItem('userData', JSON.stringify(_user))
    // 		  this.addLogin(_user)
    // 	  }
    //   }else{
    // 	// firebase.auth().signOut()
    // 	// localStorage.removeItem('userData')
    // 	// router.replace('/login')
    //   }
  },
  // destroyed() {
  //   window.removeEventListener("focus", this.reloadBalance, false);
  // },
  async created(){
	  //$("#depositChoosePromotionModal").modal('show')
    const user = localStorage.getItem('userData');
    if(user){
      const _user = JSON.parse(user);
      this.getBankDeposit();
      this.$store.dispatch('user/setUser',_user).catch(err => { console.error(err) });
      //window.addEventListener('focus', this.reloadBalance, false)
      this.reloadBalance()
    }else{
      const { code, prefix, ref } = queryString.parse(window.location.search.replace('?', ''))
      if (prefix) {
        localStorage.setItem('prefix', prefix)
      }
      if (ref) {
        localStorage.setItem('ref', ref)
        setTimeout(() => {
          $('#registerModal').modal('show')  
        }, 3000);
      }
    }
    
	// 	// firebase.auth().signOut()
	// 	// localStorage.removeItem('userData')
	// 	// router.replace('/login')
	// }
	
    const { code } = queryString.parse(window.location.search.replace('?', ''))
    if (!code) return
    const result = await this.getToken(code)
    if (result && result.access_token) {
      const { data } = await this.getProfile(result.access_token)
      this.checkLineLogin(data)
    }
	
  },
  data() {
    return {
      registerCheck:false,
      showMobile:false,
      p1:'password',
      p2:'password',
      repassword:'',
      repassword1:'',
      repassword2:'',
      password:'',
      password1:'',
      password2:'',
      fa_spin:false,
      accountOpen:false,
      withdraw: '',
      error: '',
      copyfield:'',
      canwithdraw:'ยืนยัน',
      bank:{
        scb:{
          firstname:'',
          lastname:'',
          accountno:'',
        },
        truewallet:{
          firstname:'',
          lastname:'',
          mobile:'',
        }
      },
      mobile: '',
      step: 1,
      bankselect: 0,
      firstname: '',
      lastname: '',
      account_no: '',
      userFriendId: '',
      pictureUrl: '',
      userId: '',
      email: '',
      displayName: '',
      login_mobile: '',
      login_password: '',
    }
  },
  watch: {
    // whenever question changes, this function will run
    creditincrese(newQuestion, oldQuestion) {
      if(newQuestion){
        audio.volume = 0.5;
        audio.play();
        this.$store.commit("user/SET_CREDIT_INCREASE", false) 
      }
    },
    creditdecrese(newQuestion, oldQuestion) {
      if(newQuestion){
        audio2.volume = 0.5;
        audio2.play();
        this.$store.commit("user/SET_CREDIT_DECREASE", false) 
      }
    },
    
    alertwithdraw(newalert, oldwithdraw) {
      if(newalert){
        if(Number(this.lastbonus.turncredit) <= Number(this.sites_credit.credit)){
          if(Number(this.lastbonus.canwithdraw) > 0 && Number(this.sites_credit.credit) >= Number(this.lastbonus.canwithdraw)){
            $("#withdrawModal").modal('hide')
            this.$swal({ icon: 'question', title: 'แจ้งถอน', html: `คุณทำเทิร์นครบแล้ว! จะถอนได้ ${this.lastbonus.canwithdraw} บาท<br> กดปุ่มยืนยันการถอนได้เลยค่ะ`,showCancelButton: true,confirmButtonText: 'ยืนยัน'}).then((result) => {
              if(result.isConfirmed){
                this.withdraw = this.sites_credit.credit;
                this.withdrawConfirm();
              }
            });
          }else{
            $("#withdrawModal").modal('hide')
            this.$swal({ icon: 'question', title: 'แจ้งถอน', text: `คุณทำเทิร์นครบแล้ว! กดปุ่มยืนยันการถอนได้เลยค่ะ`,showCancelButton: true,confirmButtonText: 'ยืนยัน'}).then((result) => {
              if(result.isConfirmed){
                this.withdraw = this.sites_credit.credit;
                this.withdrawConfirm();
              }
            });
          }
        }else if(Number(this.sites_credit.credit)>5){
          $("#withdrawModal").modal('hide')
          this.$swal({ icon: 'error', title: 'แจ้งถอน', text: `คุณต้องทำยอด ${this.lastbonus.turncredit} ค่ะ`}).then((result) => {
          });
        }
        this.$store.commit("user/SET_ALERT_WITHDRAWALL", false) 
      }
    }
  },
  computed: {
    sites_credit(){
      return this.$store.state.user.sites_credit;
    },
    user(){
      return this.$store.state.user.user;
    },
    creditincrese(){
      return this.$store.state.user.creditincrese;
    },
    creditdecrese(){
      return this.$store.state.user.creditdecrese;
    },
    lastbonus () {
        return this.$store.state.user.lastbonus;
      },
    alertwithdraw (){
      return this.$store.state.user.alertwithdraw;
    },
    promotions (){
      return this.$store.state.user.promotions;
    },
    gotpro (){
      return this.$store.state.user.gotpro;
    },
    promotion(){
      return this.$store.state.user.promotion;
    },
    loading () {
        return this.$store.state.user.loading
    }
  },
  methods: {
    async uploadSlip(event){
		const imageData = event.target.files[0];
		this.$store.commit('user/SET_LOADING', true)
		await firebase.firestore().collection('slip').where('userkey','==',this.user.key).where('status','==','กำลังดำเนินการ').get().then((result)=>{
			if(result.empty){
				firebase.firestore().collection('slip').add({
					userkey:this.user.key,
					code:this.user.code,
					createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
					status:'กำลังดำเนินการ',
          user:{
						code:this.user.code,
						firstname:this.user.firstname,
						lastname:this.user.lastname,
						bank:this.user.bank,
						tel:this.user.tel,
						account_no:this.user.account_no,
						bonus:(this.user.bonus)?this.user.bonus:'N',
					},
				}).then(async (result)=>{
					const imagetype = imageData.name.split('.');
					const storageRef = firebase.storage().ref(`images/slip/${result.id}.${imagetype[imagetype.length-1]}`).put(imageData);
					await storageRef.on(`state_changed`,snapshot=>{
						this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
					}, error=>{console.log(error.message)},
					()=>{
						storageRef.snapshot.ref.getDownloadURL().then((url)=>{
							firebase.firestore().collection('slip').doc(result.id).update({
								image:url,
							})
						});
					});
					this.$store.commit('user/SET_LOADING', false)
					this.$swal({ icon: 'success', title: 'แจ้งสลิปเรียบร้อย', text: `ทีมงานขออภัยในความล่าช้าและจะรีบดำเนินการให้โดยเร็วที่สุด`}).then((result) => {
					});
				})
			}else{
				this.$store.commit('user/SET_LOADING', false)
				this.$swal({ icon: 'error', title: 'แจ้งสลิป', text: `ทีมงานกำลังตรวจสอบสลิปของท่านก่อนหน้าและจะรีบดำเนินการให้โดยเร็วที่สุด`}).then((result) => {
				});
			}
		});
		
	},
    openBrowseFile(){
    	document.getElementById('my_file').click();
	  },
    reloadBalance () {
      this.fa_spin = true;
      this.$store.dispatch('user/refreshCreditAll').finally(res => { 
        setTimeout(() => {
          this.fa_spin = false; 
        }, 1000);
      }).catch(err => { console.error(err); this.fa_spin = false; })
    },
    getCredit () {
      if (this.sites_credit && this.sites_credit.credit) return this.numberWithCommas(this.sites_credit.credit)
      return 0.00
    },
    gotoPage(page,modal){
      if(modal)
        $("#"+modal).modal('hide')
      router.replace(page);
    },
    async getBankDeposit(){
		  this.bank = (await firebase.database().ref(`/bank/deposit`).once('value')).val();
	  },
	  selectBank(bank){
      if(bank ==='truewallet'){
        this.showMobile = true
      }else{
        this.showMobile = false
      }
		  this.bankselect = bank;
	  },
	  async register () {
        if (this.bankselect === 0) {
          this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณาเลือกธนาคารค่ะ!!!' })
        } else if (this.bankselect !== 'truewallet' && this.account_no.trim() === '') {
          this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณากรอกหมายเลขบัญชีค่ะ!!!' })
        } else if (this.firstname.trim() === '') {
          this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณากรอกชื่อค่ะ!!!' })
        } else if (this.lastname.trim() === '') {
          this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณากรอกนามสกุลค่ะ!!!' })
        } else {
          if(this.registerCheck === false){
            this.registerCheck = true;
            this.$store.commit('user/SET_LOADING', true)
            const status = await this.checkBankAccount()
              if(!status){
              axios({
                method: 'post',
                url: 'https://api.winner-88.co/qusercreate/create',
                data: {
                  firstname: this.firstname.toString().trim(),
                  lastname: this.lastname.toString().trim(),
                  bank: this.bankselect,
                  account_no: (this.account_no)?this.account_no:this.mobile,
                  tel: this.mobile,
                  password: this.password,
                  userFriendId: (localStorage.getItem('ref')) ? localStorage.getItem('ref') : '',
                  agent: (localStorage.getItem('prefix')) ? localStorage.getItem('prefix') : '',
                  userId: this.userId,
                  email: this.email,
                  displayName: this.displayName,
                  pictureUrl: this.pictureUrl
                }
              }).then(async response => {
                this.$store.commit('user/SET_LOADING', false)
                this.step = 4
                localStorage.removeItem('userId')
                localStorage.removeItem('faceBook')
                localStorage.removeItem('prefix')
                localStorage.removeItem('ref')
                // if (!response.data.status) {
                // 	this.$swal({ icon: 'error', title: 'Error!', text: 'โปรดติดต่อแอดมินที่ @winner-88 เพื่อแจ้งปัญหาในการสมัคร' })
                // } else {
                // 	this.step = 4
                // 	localStorage.removeItem('userId')
                // 	localStorage.removeItem('faceBook')
                // 	localStorage.removeItem('prefix')
                // 	localStorage.removeItem('ref')
                // }
              })
            }else{
              this.$store.commit('user/SET_LOADING', false)
            }
          }
		    }
    },
    async checkBankAccount () {
      let status = false
      axios({
        method: 'get',
        url: 'https://api.winner-88.co/api/checkaccount/' + this.account_no.trim()
      }).then(async response => {
        if (!response.data.status) {
          this.$swal({ icon: 'error', title: 'Error!', text: 'หมายเลขบัญชีนี้เป็นสมาชิกอยู่แล้วค่ะ หากมีปัญหาโปรดติดต่อ @winner-88' })
          this.account_no = ''
        } else {
          status = true
        }
      })
      return status
    },
    checkPassword () {
      if (this.password.toString().trim().length < 6) {
        this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณากรอกรหัสผ่านอย่างน้อย 6 ตัวอักษรค่ะ!!!' })
      } else if (this.password !== this.password2) {
        this.$swal({ icon: 'error', title: 'Error!', text: 'รหัสผ่านไม่ตรงกันค่ะ!!!' })
      } else {
        this.step = 3
      }
    },
	showpassword(p){
		if(p === 'password'){
			this.p1 = 'text';
		}else{
			this.p2 = 'text';
		}
	},
	hidepassword(p){
		if(p === 'password'){
			this.p1 = 'password';
		}else{
			this.p2 = 'password';
		}
	},
    async checkPhone () {
      if (!this.mobile.toString().trim()) {
        this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณากรอกเบอร์โทรศัพท์ค่ะ!!!' })
      } else if (this.mobile.toString().trim().length !== 10) {
        this.$swal({ icon: 'error', title: 'Error!', text: 'เบอร์โทร จำนวนตัวอักษรต้อง 10 ตัวเท่านั้นค่ะ!!!' })
      } else {
        axios({
          method: 'get',
          url: 'https://api.winner-88.co/api/checkmobile/' + this.mobile.trim()
        }).then(async response => {
          if (!response.data.status) {
            this.$swal({ icon: 'error', title: 'Error!', text: 'หมายเลขโทรศัพท์นี้เป็นสมาชิกอยู่แล้วค่ะ หากมีปัญหาโปรดติดต่อ @winner-88' })
          } else {
            this.step = 2
          }
        })
      }
    },
	isNumber(evt){
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
            evt.preventDefault();
        } else {
            return true;
        }
    },
	checkLineLogin (data) {
		if(data.userId && this.user && this.user.key){
	  		firebase.database().ref(`users/${this.user.key}`).update({userId:data.userId,pictureUrl:data.pictureUrl});
			this.$swal({ icon: 'success', title: 'Line', text: 'เชื่อมต่อระบบ Line เรียบร้อยแล้วค่ะ' })
			const user = localStorage.getItem('userData');
			if(user){
				const _user = JSON.parse(user);
				this.$store.dispatch('user/setUser',_user).catch(err => { console.error(err) });
			}
		}
    },
	async getToken (code) {
      const data = qs.stringify({
        grant_type: 'authorization_code',
        code,
        redirect_uri: this.callbackUri,
        client_id: this.clientId,
        client_secret: this.clientSecret
      })
      let response
      await axios({
        method: 'post',
        url: 'https://api.line.me/oauth2/v2.1/token',
        data: data
      }).then(async res => {
        if (res.status !== 200) {
          this.error = 'โปรดติดต่อแอดมิน'
        } else if (res.data) {
          response = res.data
        }
      })
      return response
    },
	async getProfile (token) {
      return await axios.get('https://api.line.me/v2/profile', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    },
	async addLogin(user){
    if(user && user.key){
      await axios.get('https://api.ipify.org?format=json').then(x => {
        if(x.data.ip && user.key){
              axios({
                method: 'post',
                url: 'https://api.winner-88.co/api/addlogin',
                data: {
                  key: user.key,
                  ip: x.data.ip
                }
              })
        }
      });
    }
        // await axios.get('https://checkip.amazonaws.com').then(x => {
        //   if(x){
        //     firebase.database().ref(`/users/${key}`).update({
        //       ip:x
        //     });
        //   }
        // });
	},
	nl2br (str, is_xhtml) {
		if (typeof str === 'undefined' || str === null) {
			return '';
		}
		var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
		return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
	},
	closePromotion(){
		this.$store.commit('user/SET_PROMOTION', [])
	},
	clipboardSuccessHandler () {
      this.$swal({ icon: 'success', title: 'Copy', text: 'คัดลอกเรียบร้อยแล้ว' })
    },
	gotoMain(){
		router.replace('/')
	},
	receivePromotion(){
		if(this.user && this.user.key){
			if(this.gotpro){
				this.$store.commit("user/SET_GOT_PRO", false);
				firebase.database().ref(`users/${this.user.key}`).update({bonus:'N'});
			}else{
				this.$store.commit("user/SET_GOT_PRO", true);
				firebase.database().ref(`users/${this.user.key}`).update({bonus:'Y'});
			}
		}
	},
	getUserId(){
	  	const user = localStorage.getItem('userData');
		if(user){
			const _user = JSON.parse(user);
			return _user;
		}
	},
	async moveCreditToWallet(){
		this.$store.commit('user/SET_LOADING', true)
		//this.$store.dispatch('user/moveCreditToWallet').catch(err => { console.error(err) })
		const user = this.getUserId();
    this.$store.dispatch('user/refreshCreditAll').finally(res => { 
      this.$store.dispatch('user/getLastBonus', user).finally(res => {
        this.$store.commit('user/SET_LOADING', false)
        if(this.lastbonus.turncredit){
          this.$store.commit("user/SET_ALERT_WITHDRAWALL", true);
        }
      }).catch(err => { console.error(err) })
    }).catch(err => { console.error(err); this.fa_spin = false; })
	},
	async checkPromotions(){
	  this.$store.commit('user/SET_LOADING', true)
      this.$store.dispatch('user/checkPromotions').finally(res => { this.$store.commit('user/SET_LOADING', false) }).catch(err => { console.error(err) })
    },
	getBank(){
		if(this.user.bank){
			require(`../assets/images/${this.user.bank}.jpg`)
		}
	},
	numberWithCommas (x) {
	  if(!x) x = 0;
      return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  numberWithCommas2 (x) {
	  if(!x) x = 0;
      return x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

	confirmLogout(){
      this.$swal({ icon: 'question', title: 'Log Out!', text: 'ยืนยันออกจากระบบ',showCancelButton: true,confirmButtonText: 'ตกลง'}).then((result) => {
      if (result.isConfirmed) {
        $("#accountModal").modal('hide')
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        this.$store.dispatch('user/setUser',[]).catch(err => { console.error(err) });
      }
      })
    },
	setWithdraw (value) {
		  if (value === 'plus') {
			  this.withdraw = (this.withdraw) ? this.withdraw + 10 : 10
		  } else if (value === 'minus') {
			  if (this.withdraw) { this.withdraw = (this.withdraw) ? this.withdraw - 10 : 0 }
		  } else if (value > 0) { this.withdraw = value } else { this.withdraw = 0 }
	},
	rand () {
    	return Math.random().toString(36).substr(2); // remove `0.`
	},
	token () {
    	return this.rand() + this.rand(); // to make it longer
	},
	async withdrawConfirm () {
		const user = this.getUserId();
		if (user && this.user) {
			if (this.withdraw && this.withdraw >= 1 && user.key) {
				if (Number(this.withdraw) <= Number(this.sites_credit.credit)) {
          let total = 0;
          let check = false;
          // await firebase.firestore().collection('history').where('userkey','==',user.key).where('status','==','ดำเนินการแล้ว').where('type','==','ถอน').where('createdate','>=',moment().tz('Asia/Bangkok').format('YYYY-MM-DD')+' 00:00:00').get().then((result)=>{
          //   if(result.size >= 20){
          //     check = true;
          //   }
          //   result.forEach((doc)=>{
          //     total += Number(doc.data().qty);
          //   })
          // });
          // let checkTruewallet = false;
          // if(this.user.bank === 'truewallet'){
          //   if(Number(this.withdraw)<100){
          //     checkTruewallet = true;
          //   }else if(this.withdraw.toString().trim().slice(this.withdraw.toString().trim().length - 1) != '0'){
          //     checkTruewallet = true;
          //   }
          // }
          // if(!checkTruewallet){
          if(!check){
            axios({
              method: 'post',
              url: 'https://api.winner-88.co/quserwithdraw/userwithdraw',
              data: {
                key: user.key,
                tel: user.tel,
                token: this.token(),
                credit: Number(this.withdraw)
              }
            })
            this.$swal({ icon: 'success', title: 'ทำรายการถอน', text: 'เรียบร้อยแล้วกรุณารอเงินเข้าบัญชี' })
            $("#withdrawModal").modal('hide')
          }else{
            this.$swal({ icon: 'error', title: 'ทำรายการถอน', text: 'ขออภัยท่านทำรายการถอนครบ 3 ครั้ง ทำรายการได้อีกครั้งหลังเที่ยงคืนนะคะ' });
          }
          // }else{
          //   this.$swal({ icon: 'eror', title: 'การถอน Truewallet', html: '1. ต้องมีจำนวนมากกว่า 100 <br>2. ลงท้ายด้วย 0 เช่น 110 220 เป็นต้น' })
          // }
				}else {
					this.$swal({ icon: 'error', title: 'Error!', text: 'โปรดตรวจสอบจำนวนเครดิต' })
				}
			}else{
				this.$swal({ icon: 'error', title: 'Error!', text: 'โปรดตรวจสอบจำนวนเครดิต' })
			}
		}
	},
	showTabAccount() {
      if(!this.accountOpen) this.accountOpen = true; else this.accountOpen = false;
    },
	async changePassword(){
		if(this.repassword && this.repassword1 && this.repassword2){
			if(this.repassword1.length<6){
				this.$swal({ icon: 'warning', title: 'Password', text: `โปรดระบุรหัสผ่านมากกว่า 6 หลักค่ะ`}).then((result) => {
				});
				return;
			}else if(this.repassword1 != this.repassword2){
				this.$swal({ icon: 'warning', title: 'Password', text: `โปรดตรวจสอบรหัสผ่านใหม่อีกครั้งค่ะ`}).then((result) => {
				});
				return;
			}
			const user = this.getUserId();
			const password = (await firebase.database().ref(`/users/${user.key}/password`).once('value')).val();
			if(password != this.repassword){
				this.$swal({ icon: 'warning', title: 'Password', text: `โปรดตรวจสอบรหัสผ่านเดิมค่ะ`}).then((result) => {
				});
				return;
			}
			this.$store.commit('user/SET_LOADING', true)
			axios({
				method: 'post',
				url: 'https://api.winner-88.co/api/resetpassword',
				data: {
					key: user.key,
					password: this.repassword1
				}
			}).then(async (result)=>{
				if(result.data && result.data.status){
					await firebase.auth().signInWithEmailAndPassword(user.tel.trim().toLowerCase() + '@winner88.com', this.repassword1.trim()).then(async (res2) => {
						if (res2.user) {
							this.$swal({ icon: 'success', title: 'Password', text: `เปลี่ยนรหัสผ่านเรียบร้อยแล้วค่ะ`}).then((result) => {
						});
						}
					}).catch(() => {
					})
				}
				this.$store.commit('user/SET_LOADING', false)
			}).catch(()=>{
				this.$store.commit('user/SET_LOADING', false)
			})
			$("#accountModalMobile").modal('hide')
			$("#accountModal").modal('hide')
		}
	},
	login () {
      if (!this.login_mobile) {
        this.$swal({ icon: 'warning', title: 'ข้อความ!', text: 'โปรดระบุเบอร์มือถือค่ะ!!!' })
        $('#login_mobile').focus()
      } else if (!this.login_password) {
        this.$swal({ icon: 'warning', title: 'ข้อความ!', text: 'โปรดระบุ Password ค่ะ!!!' })
        $('#login_password').focus()
      } else {
		this.$store.commit('user/SET_LOADING', true)
        axios({
          method: 'post',
          url: 'https://api.winner-88.co/api/login',
          data: {
            mobile: this.login_mobile,
            password: this.login_password
          }
        }).then(async res => {
		      this.$store.commit('user/SET_LOADING', false)
          if (res.data && !res.data.status) {
            this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'เบอร์โทรศัพท์ / รหัสผ่าน ไม่ถูกต้องค่ะ!!!' })
            $('#login_mobile').focus()
          } else {
            firebase.auth().signInWithEmailAndPassword(this.login_mobile.trim().toLowerCase() + '@winner88.com', this.login_password.trim()).then(async (res2) => {
              if (res2.user) {
                localStorage.setItem('userData', JSON.stringify(res.data))
				        this.$store.dispatch('user/setUser',res.data).catch(err => { console.error(err) });
                $("#loginModal").modal('hide')
                this.reloadBalance()
              } else {
				        this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'โปรดติดต่อแอดมินค่ะ!!!' })
              }
            }).catch((err) => {
              this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'เบอร์โทรศัพท์ / รหัสผ่าน ไม่ถูกต้องค่ะ!!!' })
            })
          }
        })
      }
    },
    showModal(oldModal,newModal){
      $("#"+oldModal).modal('hide');
      $("#"+newModal).modal('show');
    }
  }
}
</script>
<style scoped>
.glow {
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
    }

    @-webkit-keyframes glow {
    from {
text-shadow: 0 0 5px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 5px #dbc47a, 0 0 5px #dbc47a, 0 0 5px #dbc47a;
    }
    
    to {
text-shadow: 0 0 5px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 5px #dbc47a, 0 0 5px #dbc47a, 0 0 5px #dbc47a;
    }
}
@media (max-width: 500px) { 
	.x-header #headerBrand .navbar-brand .-logo {
		width: 80px;
	}
}
.-balance-container .-user-balance .-inner-box-wrapper2 {
    position: relative;
    margin-right: 5px;
    margin-left: 15px;
    padding: 6px 24px 6px 0px;
    font-weight: 500;
    border-radius: 20px;
    color: #fff;
    text-align: right;
}
.x-logged .-profile-container .-btn-wrapper .-profile-name2 {
	font-family: 'Kanit', sans-serif;
    font-size: 1.2rem;
  /*  color: #e5e5e5; */
    font-weight: 200;
    margin-right: 2px;
    padding-left: 10px;
    color: #f9f9d6;
	text-align: center;
}
.x-logged .-profile-container .-btn-mobile-wrapper .-profile-name2 {
	font-family: 'Kanit', sans-serif;
    font-size: 1.1rem;
  /*  color: #e5e5e5; */
    font-weight: 200;
    margin-right: 2px;
    padding-left: 10px;
    color: #f9f9d6;
	text-align: center;
}

.-balance-container .-user-balance .-btn-balance2 {
    position: absolute;
    right: 10px;
    padding: 0;
    color: #FFFFFF;
    background: transparent;
    border: none;
}
.cCsozd {
    display: inline-block;
    padding: 0.5rem 1rem;
    height: 44px;
    font-size: 1em;
    line-height: 1.5;
    border: none;
    border-radius: 0.25rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
}
.ewHin {
    background: rgb(0, 187, 0);
    color: rgb(255, 255, 255);
    height: 50px;
    min-width: 180px;
    border-radius: 25px;
    padding-left: 34px;
    padding-right: 34px;
}
.ewHin .icon {
    height: 18px;
    margin: 0px 0.5rem;
    vertical-align: sub;
    border: 0px;
}



.mid {
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Switch starts here */
.rocker {
  display: inline-block;
  position: relative;
  /*
  SIZE OF SWITCH
  ==============
  All sizes are in em - therefore
  changing the font-size here
  will change the size of the switch.
  See .rocker-small below as example.
  */
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #888;
  width: 7em;
  height: 4em;
  overflow: hidden;
  border-bottom: 0.5em solid #eee;
}

.rocker-small {
  font-size: 0.75em; /* Sizes the switch */
  margin: 1em;
}

.rocker::before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #999;
  border: 0.5em solid #eee;
  border-bottom: 0;
}

.rocker input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-left,
.switch-right {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  width: 3em;
  transition: 0.2s;
}

.switch-left {
  height: 2.4em;
  width: 2.75em;
  left: 0.85em;
  bottom: 0.4em;
  background-color: #ddd;
  transform: rotate(15deg) skewX(15deg);
}

.switch-right {
  right: 0.5em;
  bottom: 0;
  background-color: #bd5757;
  color: #fff;
}

.switch-left::before,
.switch-right::before {
  content: "";
  position: absolute;
  width: 0.4em;
  height: 2.45em;
  bottom: -0.45em;
  background-color: #ccc;
  transform: skewY(-65deg);
}

.switch-left::before {
  left: -0.4em;
}

.switch-right::before {
  right: -0.375em;
  background-color: transparent;
  transform: skewY(65deg);
}

input:checked + .switch-left {
  background-color: #0084d0;
  color: #fff;
  bottom: 0px;
  left: 0.5em;
  height: 2.5em;
  width: 3em;
  transform: rotate(0deg) skewX(0deg);
}

input:checked + .switch-left::before {
  background-color: transparent;
  width: 3.0833em;
}

input:checked + .switch-left + .switch-right {
  background-color: #ddd;
  color: #888;
  bottom: 0.4em;
  right: 0.8em;
  height: 2.4em;
  width: 2.75em;
  transform: rotate(-15deg) skewX(-15deg);
}

input:checked + .switch-left + .switch-right::before {
  background-color: #ccc;
}

/* Keyboard Users */
input:focus + .switch-left {
  color: #333;
}

input:checked:focus + .switch-left {
  color: #fff;
}

input:focus + .switch-left + .switch-right {
  color: #fff;
}

input:checked:focus + .switch-left + .switch-right {
  color: #333;
}
.preloader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 9;
    background: rgba(0,0,0,0.7);
}
.circular-spinner {
  margin-top: 30px;
  width: 50px;
  height: 50px;
  /* border: 7px solid white;
  border-top: 7px solid #fc0;
  border-radius: 50%; */
  /* animation: rotate 0.5s linear infinite; */
  position: absolute;
  left: 40%;
  top: 45%;
}
@media (max-width: 990px){
  .circular-spinner {
      top: 30%;
      left: 30%;
  }
}
@media (max-width: 660px){
  .circular-spinner {
      top: 30%;
      left: 15%;
  }
}
@media (max-width: 991.98px) {
	#registerModal.-with-half-size .-modal-content {
    min-height: 100%;
    height: 100%;
	}
}
#my_file {
    display: none;
}
</style>